// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/explore.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/explore.tsx");
  import.meta.hot.lastModified = "1712947911932.2556";
}
// REMIX HMR END

import { Avatar, Box, Container, Divider, HStack, LinkBox, LinkOverlay, VStack, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import { json } from "@remix-run/node";
import { Link, useLoaderData } from "@remix-run/react";
import { useEffect } from "react";
import UprateContainer from "~/components/uprate-container";
import UprateHostLinkCard from "~/components/uprate-host-link-card";
import UpratePrimaryButton from "~/components/uprate-primary-button";
import UprateSecondaryButton from "~/components/uprate-secondary-button";
import { UprateB3, UprateB5, UprateH3, UprateH5, UprateH6, UprateH7 } from "~/components/uprate-styles";
import { getOperator } from "~/utils/auth.server";
import { getPublicHosts } from "~/utils/hosts.server";
export const loader = async ({
  request
}) => {
  const operator = await getOperator(request);
  const url = new URL(request.url);
  const q = url.searchParams.get("q");
  const hosts = await getPublicHosts(q);
  return json({
    operator,
    hosts,
    q
  });
};
export default function Explore() {
  _s();
  const {
    operator,
    hosts,
    q
  } = useLoaderData();
  useEffect(() => {
    const searchField = document.getElementById("q");
    if (searchField instanceof HTMLInputElement) {
      searchField.value = q || "";
    }
  }, [q]);
  return <UprateContainer operator={operator}>
      <Container maxW={"container.xl"} pb={8}>
        <VStack gap={12} mt={8}>
          <VStack gap={8} textAlign={"center"}>
            <LinkBox>
              <Box rounded={"lg"} borderColor={useColorModeValue("purple.200", "purple.800")} borderWidth={2} py={2} px={4} mt={4} mb={2}>
                <UprateB5>
                  ✨ OpenAI's GPT Store just launched.{" "}
                  <LinkOverlay href="https://angie.uprate.chat" target="_blank">
                    Talk to Angie to get your brand on the GPT Store
                  </LinkOverlay>
                </UprateB5>
              </Box>
            </LinkBox>

            <UprateH3>Commerce Through Conversations</UprateH3>
            <VStack>
              <UprateB3>
                Generative AI platform designed to enhance customer experience,
                boost conversions,
              </UprateB3>
              <UprateB3>
                maintain brand consistency, and strategically harness AI across
                various conversational sales channels.
              </UprateB3>
            </VStack>
            <HStack>
              <UpratePrimaryButton as={Link} to={"/login"} size={"lg"}>
                Get Started
              </UpratePrimaryButton>
              <UprateSecondaryButton as={Link} to={"/about"} size={"lg"}>
                Learn More
              </UprateSecondaryButton>
            </HStack>
          </VStack>

          <HStack onClick={() => window.open("https://angie.uprate.chat", "_blank")} cursor={"pointer"} gap={4}>
            <Avatar src="https://uprate-v1-photos.nyc3.cdn.digitaloceanspaces.com/presets/lisa.png"></Avatar>
            <VStack>
              <UprateH7>Chat with</UprateH7>
              <UprateH7>Angie</UprateH7>
            </VStack>
          </HStack>

          <Divider />

          <VStack gap={4} textAlign={"center"}>
            <UprateH6>
              Deploy Sales Assistants in existing E-Commerce Channels.
            </UprateH6>
            <Wrap spacing={8} justify={"center"}>
              <Avatar src="assets/logo/shopify.png" borderRadius={"none"} name="Shopify" h={"60px"} w={"60px"} opacity={0.7}></Avatar>
              <Avatar src="assets/logo/magento.png" borderRadius={"none"} name="Magento" h={"60px"} w={"60px"} opacity={0.7}></Avatar>
              <Avatar src="assets/logo/salesforce.png" borderRadius={"none"} name="Salesforce" h={"60px"} w={"60px"} opacity={0.7}></Avatar>
              <Avatar src="assets/logo/domain.png" borderRadius={"none"} name="Custom Domain" h={"60px"} w={"60px"} opacity={0.7}></Avatar>
            </Wrap>
          </VStack>
          <VStack gap={4} textAlign={"center"}>
            <UprateH6>Create New Sales Channels Powered by AI</UprateH6>
            <Wrap spacing={8} justify={"center"}>
              <Avatar src="assets/logo/chatgpt.png" borderRadius={"none"} name="ChatGPT" opacity={0.7}></Avatar>
              <Avatar src="assets/logo/copilot.png" borderRadius={"none"} name="Microsoft Copilot" opacity={0.7}></Avatar>
              <Avatar src="assets/logo/instagram.png" borderRadius={"none"} name="Instagram" opacity={0.7}></Avatar>
              <Avatar src="assets/logo/whatsapp.png" borderRadius={"none"} name="WhatsApp" opacity={0.7}></Avatar>
              <Avatar src="assets/logo/apple.png" borderRadius={"none"} name="Apple Business Chat" opacity={0.7}></Avatar>
            </Wrap>
          </VStack>
          <Divider />

          <VStack gap={8}>
            <UprateH5 textAlign={"center"}>
              Try hosts built by our customers
            </UprateH5>
            <Wrap spacing={"20px"} justify={"center"}>
              {hosts.map(host => <WrapItem key={host.oId}>
                  <UprateHostLinkCard host={host} key={host.oId} avatarOn={true} noOfDescriptionLines={1} descriptionOn={true} w={"320px"} avatarSize={12} link={`http://${host.specs.domain}`} target="_blank" />
                </WrapItem>)}
            </Wrap>
          </VStack>
        </VStack>
      </Container>
    </UprateContainer>;
}
_s(Explore, "0n9JwQBTAXfqMFvOFyIW0mCLd14=", false, function () {
  return [useLoaderData, useColorModeValue];
});
_c = Explore;
var _c;
$RefreshReg$(_c, "Explore");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;